// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $($("#main-navigation").hasClass("hidden") ? openNav() : closeNav());
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() > 960) {
    closeNav();
  }
});

// Detect user scroll and show/hide nav
var lastScrollTop = 0;
var delta = 20;

$(window).scroll(function () {
  var nowScrollTop = $(this).scrollTop();

  if (nowScrollTop > 75) {
    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
      if (nowScrollTop > lastScrollTop) {
        // Scroll down
        $("body > header").addClass("-translate-y-full");
      } else {
        // Scroll up
        $("body > header").removeClass("-translate-y-full");
      }
      lastScrollTop = nowScrollTop;
    }
  }
});

// Scroll to top on 'skip to content' focus
$('a[href="#main"]').focus(function () {
  window.scrollTo({ top: 0, behavior: "smooth" });
});

$("body > header a").focus(function () {
  $("body > header").removeClass("-translate-y-full");
});

$(window).on("load scroll", function () {
  var nowScrollTop = $(this).scrollTop();
  var hero = $(".hero").innerHeight();

  if (nowScrollTop > hero) {
    $(".headerBg").addClass("opacity-100");
  } else {
    $(".headerBg").removeClass("opacity-100");
  }
});

function openNav() {
  $("#main-navigation").addClass("block").removeClass("hidden");
  $(".hamburgerOpen").addClass("hidden");
  $(".hamburgerClose").removeClass("hidden");
}

function closeNav() {
  $("#main-navigation").addClass("hidden").removeClass("block");
  $(".hamburgerClose").addClass("hidden");
  $(".hamburgerOpen").removeClass("hidden");
}
