$(function () {
  "use strict";

  // Require modules
  require("./modules/navigation.js");
  require("./modules/tables.js");
  require("./modules/transitions.js");
  require("./modules/blog.js");
  require("./modules/form.js");
});

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});
