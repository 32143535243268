// Hover effect for work descriptions
$(".hover-text").hover(
  function () {
    if ($(window).width() >= 640) {
      $(this).children("p").slideToggle(100);
    }
  },
  function () {
    if ($(window).width() > 640) {
      $(this).children("p").slideToggle(100);
    }
  }
);

// Make sure links are shown on mobile
$(window).on("resize", function () {
  if ($(window).width() < 640) {
    $(".hover-text").children("p").show();
  }
});

// Add animation classes on scroll
const targets = document.querySelectorAll("[data-animate]");

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      var animationType = entry.target.dataset.animate;
      entry.target.classList.add("animate-" + animationType);
      observer.unobserve(entry.target);
    }
  });
});

// Wait for element to be on screen
targets.forEach((target) => {
  target.classList.add("opacity-0");
  observer.observe(target);
});
