// Calculate read time of blog posts
$(function () {
  if ($("#blog-content").length) {
    var txt = $("#blog-content")[0].textContent;
    var wordCount = txt.replace(/[^\w ]/g, "").split(/\s+/).length;
    var readingTimeInMinutes = Math.floor(wordCount / 228) + 1;
    var readingTimeAsString = readingTimeInMinutes + " minute read";

    $(".read-time").html(readingTimeAsString);
  }
});
