// Responsive tables
$(".maintenance-table").each(function () {
  var table = $(this);
  var columns = table.find("th");

  for (var i = 1; i < columns.length; i++) {
    var newtable = $(
      '<table class="responsive">' +
        "<thead>" +
        "<tr><th>" +
        table.find("th:nth-child(" + [i + 1] + ")").text() +
        "</th><th></th></tr>" +
        "</thead>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        table.find("tr:nth-child(1) td:nth-child(1)").text() +
        "</td>" +
        "<td>" +
        table.find("tr:nth-child(1) td:nth-child(" + [i + 1] + ")").html() +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>" +
        table.find("tr:nth-child(2) td:nth-child(1)").text() +
        "</td>" +
        "<td>" +
        table.find("tr:nth-child(2) td:nth-child(" + [i + 1] + ")").html() +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>" +
        table.find("tr:nth-child(3) td:nth-child(1)").text() +
        "</td>" +
        "<td>" +
        table.find("tr:nth-child(3) td:nth-child(" + [i + 1] + ")").html() +
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "</table>"
    );

    $(".responsive-tables").append(newtable);
  }
});

$(".maintenance-table td, .responsive-tables td").each(function () {
  var text = $(this).text();
  $(this).html(
    text.replace("%yes-tick%", '<i class="fas fa-check fa-fw"></i>')
  );
});
