// Set recaptcha to read only
$(window).on("load", function () {
  if ($("#g-recaptcha-response").length) {
    const textarea = document.getElementById("g-recaptcha-response");

    textarea.setAttribute("aria-hidden", "true");
    textarea.setAttribute("aria-label", "do not use");
    textarea.setAttribute("aria-readonly", "true");
  }
});
